@tailwind base;
@tailwind components;
@tailwind utilities;

.background-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

@layer base {
  @font-face {
    font-family: 'bisonbold';
    src: url('../fonts/bison_bold-webfont.woff2') format('woff2'),
      url('../fonts/bison_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'bisonlight';
    src: url('../fonts/bison_light-webfont.woff2') format('woff2'),
      url('../fonts/bison_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}